import React from 'react'
import {Link} from 'gatsby'
// import InstagramFeed from './InstagramFeed'
import './Footer.css'

export default () => (
  <div>
    <h2 className="taCenter">
    </h2>
    <br />
    {/*<InstagramFeed count="8" />*/}
    <footer className="footer">
      <div className="container taCenter">
        <span>
          John Medland © {new Date().getFullYear()}. Created with Open-Source Software - <Link to="/licences">See Licences</Link>
        </span>
      </div>
    </footer>
  </div>
)
